<template>
  <div>
    <div class="all">
      <div class="bannerBox">
        <h3>项目详情</h3>
      </div>
      <div class="breads">
        <div class="bread w">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
              >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item
              :to="{
                path: `/project`
              }"
              >我们的产品</el-breadcrumb-item
            >
            <el-breadcrumb-item class="active">{{
              this.$route.params.name
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <!-- 内容一 -->
      <article>
        <div class="imgBox">
          <accordion :imgList="cont1ImgList" />
        </div>
        <div class="textBox">
          <h1>{{ projectName }}</h1>
          <p>{{ detailIntroduce }}</p>
        </div>
      </article>
      <div class="content2Box">
        <section>
          <header>
            <img
              src="../../../public/img/customService/detailsIcon.png"
              alt=""
            />
            <h3>项目截图</h3>
          </header>
          <div class="itemBox">
            <img
              v-for="(item, index) in screenshotList"
              :key="index"
              :src="item"
              alt=""
            />
          </div>
        </section>
        <section>
          <div v-for="item in defineList" :key="item.title">
            <header>
              <img
                src="../../../public/img/customService/detailsIcon.png"
                alt=""
              />
              <h3>{{ item.title }}</h3>
            </header>
            <p>{{ item.desc }}</p>
            <img
              v-for="imgs in item.picArr"
              :key="imgs.uid"
              :src="imgs.url"
              :alt="imgs.name"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { findItemDetails, findItem } from '../../api/parentPage'
import accordion from '../../components/accordion'

export default {
  name: '项目分类详情',
  data() {
    return {
      itemName: '产品详情',
      cont1ImgList: [], //图片数组
      projectName: '',
      detailIntroduce: '',
      mainFunction: null,
      screenshotList: '', //截图
      defineList: []
    }
  },
  components: { accordion },
  created() {
    if (this.$route.params.name == '产品分类') {
      this.itemName = '产品详情'
    }
    // else if(this.$route.params.name=="产品分类"){
    // }
    this._initData()
  },
  mounted() {
    // var aEleS = document.querySelectorAll("article>.imgBox>a");
    // console.log(aEleS);
    // aEleS.forEach((el, x) => {
    //   el.style.zIndex = 5 - x;
    // });
  },
  methods: {
    _initData() {
      //查询菜单项目详情
      findItemDetails({ id: this.$route.params.id }).then((res) => {
        if (res.status == 200) {
          // console.log(111, res.data);
          this.projectName = res.data.projectName
          this.detailIntroduce = res.data.detailIntroduce
          this.screenshotList = res.data.projectBanner.split(',')
          this.mainFunction = res.data.mainFunction
          this.defineList = JSON.parse(res.data.defineList)
          console.log(this.defineList)
          //处理轮播图
          res.data.projectDetailImg.split(',').forEach((item, index) => {
            let obj = {}
            obj.imgUrl = item
            obj.id = index
            if (index == 0) {
              obj.ifEffect = true
            } else {
              obj.ifEffect = false
            }
            this.cont1ImgList.push(obj)
          })
        }
        var aEleS = document.querySelectorAll('article>.imgBox>a')
      })
    },
    positionChange(i) {
      let val = this.cont1ImgList[i]
      // this.cont1ImgList.splice(i, 1);
      // this.cont1ImgList.splice(0, 0, val);
      let aEleS = document.querySelectorAll('article>.imgBox>a')
      aEleS.forEach((el, x) => {
        if (x == i) {
          el.style.zIndex = 6
          el.style.left = '0px'
          // this.cont1ImgList[x].ifEffect = true;
          // el.style.animation=" imgEffect 1s infinite";
          this.cont1ImgList.splice(i, 1)
          this.cont1ImgList.splice(0, 0, val)
        } else if (i - x > 0) {
          // console.log("i-x>0", i, x);
          el.style.left = `${(x + 1) * 160}px`
          this.cont1ImgList[x].ifEffect = false
          el.style.zIndex = 5 - x
        } else if (i - x < 0) {
          // console.log("i-x<0", i, x);
          el.style.zIndex = 5 - x
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.all {
  background: rgba(0, 0, 0, 0.1);
  padding-bottom: 130px;
  .bannerBox {
    width: 100%;
    height: 320px;
    background-image: url('../../../public/img/customService/detailsBanner.png');
    > h3 {
      width: 1200px;
      text-align: end;
      font-family: 'OPPOSans-H';
      font-size: 22px;
      color: #fff;
      position: absolute;
      position: relative;
      top: 200px;
      // right: 50px;
      margin: auto;
    }
  }

  // 面包屑导航栏
  aside {
    background: #fff;
    > div {
      width: 1200px;
      height: 60px;
      line-height: 60px !important;
      margin: auto;
      ::v-deep .el-breadcrumb__item {
        > span {
          font-weight: 400;
          color: #515151 !important;
        }
      }
    }
  }
  // 内容一
  // 定义图片动画
  @keyframes imgEffect {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  article {
    margin: 200px auto 20px;
    width: 1200px;
    // height: 520px;
    border-radius: 16px;
    padding: 250px 0 40px 80px;
    box-sizing: border-box;
    background: #fff;
    position: relative;

    > .imgBox {
      width: 1040px;
      height: 300px;
      //   border-radius: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      // overflow: hidden;
      top: -120px;
      left: 80px;
      position: absolute;
      > a {
        position: absolute;
        transition: all linear 0.5s;
        > img {
          width: 400px;
          height: 300px;
          border-radius: 16px;
          object-fit: cover;
          // position: relative;
        }
      }
      > a:first-child {
        z-index: 6;
        > img {
          transition: all linear 0.5s;
        }
      }
      > a:nth-child(2) {
        left: 150px;
        z-index: 4;
        > img {
          left: -160px;
        }
      }
      > a:nth-child(3) {
        left: 300px;
        z-index: 3;
        > img {
          left: -320px;
        }
      }
      > a:nth-child(4) {
        left: 450px;
        z-index: 2;
        > img {
          left: -480px;
        }
      }
      > a:nth-child(5) {
        left: 600px;
        z-index: 1;
        > img {
          left: -660px;
        }
      }
      .ifEffect {
        // opacity: 1;
        transition: all 0.5s linear;
        // animation: imgEffect 1s infinite;
      }
    }
    > .textBox {
      width: 966px;
      // position: absolute;
      // left: 80px;
      // top: 230px;
      text-align: start;
      > h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 47px;
        color: #484848;
        margin: 0 0 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > p {
        font-weight: 400;
        font-size: 16px;
        line-height: 36px;
        color: #333333;
        text-align: start;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
  }
  .content2Box {
    width: 1200px;
    padding: 80px;
    border-radius: 16px;
    background: #fff;
    box-sizing: border-box;
    margin: auto;
    > section {
      width: 966px;
      box-sizing: border-box;
      margin: auto;
      > header {
        position: relative;
        > h3 {
          padding-left: 35px;
          text-align: start;
          margin: 0 0 40px;
        }
        img {
          position: absolute;
          left: 0px;
          width: 25px;
        }
      }
      > .itemBox {
        // padding-bottom: 50px;
        border-radius: 16px;
        // background: rgba(0, 0, 0, 0.1);
        // opacity: 0.1;
        > img {
          width: 966px;
          object-fit: cover;
          border-radius: 16px;
          margin-bottom: 15px;
        }
      }
      > p {
        font-weight: 400;
        font-size: 16px;
        line-height: 36px;
        color: #333333;
        text-align: start;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
    > section:last-child {
      margin-top: 50px;
      > div {
        margin-bottom: 30px;
        > header {
          position: relative;
          > h3 {
            padding-left: 35px;
            text-align: start;
            margin: 0 0 40px;
          }
          img {
            position: absolute;
            left: 0px;
            width: 25px;
          }
        }
        > p {
          // padding: 0 40px;
          text-align: start;
          font-weight: 400;
          font-size: 16px;
          line-height: 36px;
          color: #333333;
        }
        > img {
          width: 996px;
          border-radius: 16px;
          object-fit: cover;
        }
      }
    }
  }
}
.breads {
  width: 100%;
  height: 60px;
  background-color: #eee;
  .bread {
    height: 100%;
    display: flex;
    align-items: center;
    .active /deep/.el-breadcrumb__inner {
      color: #2473e9;
      font-size: 18px;
      font-weight: 400;
    }
  }
  /deep/.el-breadcrumb__item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #333333;
  }
}
</style>
