import { get, post } from './index'

// 根据分类id查询广告列表
export const findAdById = (p) => {
  return get('/api/advertising/findByClassifyId', p)
}

// 查询banner菜单列表
export const findBanner = (p) => {
  return get('/api/projectGroup/selectAll', p)
}
// 查询banner菜单子项目
export const findBannerItem = (p) => {
  return post('/api/project/selectPage', p)
}
// 获取所有案列
export const getCaseList = (p) => {
  return post('/api/project/selectPage', p)
}
// 查询公司信息
export const findCompany = (p) => {
  return get('/api/company/select', p)
}
// 获取合作伙伴
export const getCooperativePartner = (p) => {
  return get('/api/aboutUs/pageMessage', p)
}
