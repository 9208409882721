<template>
  <div class="box">
    <div class="head">
      <img src="../../assets/imgs/service/head.png" alt="" />
      <div>
        高品质、多功能、高效、用户友好、安全稳定、定制化的软件
        <br />
        为用户提供最好的使用体验和最全面的功能支持
      </div>
    </div>
    <div class="content w">
      <!-- <h1>我们的产品</h1> -->
      <div class="choices">
        <div
          class="choice"
          :class="currentIndex == index ? 'active' : ''"
          v-for="(choice, index) in choiceLists"
          :key="choice.id"
          @click="handleChange(choice, index)"
        >
          {{ choice.name }}
        </div>
      </div>
      <div class="caseBox">
        <div
          v-for="item in caseLists"
          @click="customerCaseDetails(item)"
          :key="item.id"
        >
          <img :src="item.projectDetailImg" :alt="item.name" />
          <div>
            <h5>{{ item.projectName }}</h5>
            <p>{{ item.caseCategoryName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findBanner } from '../../api/home'
import { getCaseClassification } from '../../api/customService'
import { findMoreCustomerStories, findItem } from '../../api/parentPage'
export default {
  name: 'Box',
  components: {},
  data() {
    return {
      choiceLists: [],
      currentIndex: 0,
      caseLists: [],
      caseImg: require('../../assets/imgs/service/case.png'),
      caseId: '',
      projects: []
    }
  },
  methods: {
    handleChange(choice, index) {
      this.caseId = choice.id
      this.currentIndex = index
      this.getCaseLists()
    },

    _initData() {
      findBanner().then((res) => {
        if (res.status === 200) {
          this.choiceLists = res.data
          this.caseId = res.data[0].id
          console.log('acascasc', res.data, this.caseId)
          this.getCaseLists()
        }
      })
    },

    getCaseLists() {
      // 查询菜单对应信息
      findItem({ data: { groupId: this.caseId } }).then((res) => {
        if (res.status == 200) {
          console.log('contBox', res.data.rows)
          this.caseLists = res.data.rows
        }
      })
    },
    customerCaseDetails(item) {
      this.$router.push({
        path: `/project/${item.projectName}/${item.groupId}/${item.id}`
      })
    }
  },
  created() {
    this._initData()
  }
}
</script>

<style lang="less" scoped>
.box {
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  .head {
    width: 100%;
    height: 650px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    div {
      width: 670px;
      position: absolute;
      top: 40%;
      right: 20%;
      z-index: 2;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 40px;
      color: #ffffff;
      text-align: right;
    }
  }
  .breads {
    width: 100%;
    height: 60px;
    background-color: #eee;
    .bread {
      height: 100%;
      display: flex;
      align-items: center;
      .active /deep/.el-breadcrumb__inner {
        color: #2473e9;
        font-size: 18px;
        font-weight: 400;
      }
    }
    /deep/.el-breadcrumb__item {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;
      color: #333333;
    }
  }
  .content {
    padding: 80px 0 140px;
    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 48px;
      color: #333333;
      margin-bottom: 80px;
    }
    .choices {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 60px;
      .choice {
        // width: 134px;
        padding: 6px 30px;
        box-sizing: border-box;
        // height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 200px;
        margin: 0 40px 20px 0;
        color: #333333;
        cursor: pointer;
        transition: all 0.2s linear;
        border: 1px solid transparent;
        box-sizing: border-box;
        font-family: 'OPPOSans-M';
        font-size: 18px;
        font-weight: 400;
        &.active {
          color: #2473e9;
          border: 1px solid #2473e9;
          font-weight: 700;
          font-family: 'OPPOSans-B';
        }
      }
    }
    .items {
      display: flex;
      flex-wrap: wrap;
      .item {
        cursor: pointer;
        width: 280px;
        height: 210px;
        margin: 0 20px 20px 0;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: linear-gradient(
              0deg,
              rgba(43, 123, 236, 0.2),
              rgba(43, 123, 236, 0.2)
            ),
            url('../../assets/imgs/service/mask.png');
          background-repeat: no-repeat;
          background-position: bottom;
          transition: all 0.2s linear;
          font-family: 'OPPOSans-B';
          .title {
            position: relative;
            width: 100%;
            height: 100%;
            h5 {
              position: absolute;
              bottom: 20px;
              left: 50%;
              font-weight: 400;
              font-size: 22px;
              line-height: 32px;
              color: #ffffff;
              transform: translateX(-50%);
            }
            p {
              position: absolute;
              bottom: 6px;
              left: 50%;
              font-weight: 400;
              font-size: 16px;
              line-height: 32px;
              color: #ffffff;
              transform: translateX(-50%);
            }
          }
        }
        &:hover {
          transform: scale(1.1);
          .mask {
            opacity: 1;
            background: linear-gradient(
                0deg,
                rgba(43, 123, 236, 0.2),
                rgba(43, 123, 236, 0.2)
              ),
              url('../../assets/imgs/service/mask.png');
            background-repeat: no-repeat;
            background-position: bottom;
          }
        }
      }
    }
    .caseBox {
      display: flex;
      flex-wrap: wrap;
      > div {
        width: 268px;
        height: 208px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        margin-right: 30px;
        margin-bottom: 30px;
        transition: all linear 0.3s;
        border: 1px solid #e6e6e6;
        border-radius: 16px;
        > img {
          width: 270px;
          height: 210px;
          border-radius: 4px;
          object-fit: cover;
        }
        &:hover {
          transform: scale(1.1);

          > div {
            opacity: 1;
          }
        }
        > div {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          position: absolute;
          top: 0;
          opacity: 0;
          color: #fff;
          // background: linear-gradient(56deg, rgb(255, 255, 255) 0%, rgb(132, 183, 237) 50%, rgb(255, 235, 211) 100%)
          background: linear-gradient(
            175deg,
            transparent 0%,
            transparent 50%,
            rgba(8, 103, 235, 0.902) calc(50% + 1px)
          );
          transition: all linear 0.3s;
        }
        > div > h5 {
          text-align: center;
          font-family: 'OPPOSans-M';
          font-size: 20px;
          margin: 0;
          margin-top: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > div > p {
          text-align: center;
          font-family: 'OPPOSans-M';
          font-size: 16px;
          margin: 0;
          margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
