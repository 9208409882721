import { get, post } from './index'
// 查询公司详情
export const findCompanyDetails = (p) => {
  return get('/api/company/select', p)
}

//基本信息
export const getBasicInfo = (p) => {
  return get('/api/customservice/baseInfo/detail', p)
}
// 获取所有案列种类
export const getCaseClassification = (p) => {
  return get('/api/customservice/caseCategory/list', p)
}
// 获取所有案列
export const getCaseList = (p) => {
  return post('/api/customservice/customerCase/page', p)
}
// 获取轮播客户案列
export const getRotationCase = (p) => {
  return get('/api/customservice/customerCase/carousel', p)
}

// // 获取解决方案
export const getSolutionDetails = (p) => {
  return get('/api/customservice/solutionSetting/detail', p)
}
//   所有可展示的解决方案(前台)
export const getSolutionList = (p) => {
  return get('/api/customservice/solution/list', p)
}

// 获取合作伙伴
export const getCooperativePartner = (p) => {
  return get('/api/aboutUs/pageMessage', p)
}
